/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// How to use all this: https://github.com/rails/webpacker#usage

// Log out Hello world on each request 😱
// console.log('Hello World from Webpacker!')
require("@rails/ujs").start();
// require("turbolinks").start();
require("jquery");
require("jquery-ui");
require("jquery-asPieProgress");
require("jscrollpane/script/jquery.jscrollpane.min");
require("embrosa-styles/javascripts/lib/bootstrap/bootstrap.min");
require("datatables.net-bs4/js/dataTables.bootstrap4.min");
require("/vendor/assets/javascripts/lib/sweetalert")
require("select2");
const images = require.context("../images", true);
import moment from "moment";
import daterangepicker from "daterangepicker";

window.moment = moment;
window.daterangepicker = daterangepicker;

require("../stylesheets/application.scss");

window.setCookie = function(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue}; ${expires}; path=/`;
};

window.getCookie = function(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

$(document).ready(function() {
  $(".select-2.server-side").each(function() {
    $(this).select2({
      allowClear: true,
      placeholder: $(this).attr("placeholder") || "",
      minimumInputLength: 3,
      ajax: {
        url(params) {
          console.log($(this).attr("endpoint_url"));
          return $(this).attr("endpoint_url");
        },
        dataType: "json",
        data(params) {
          const query = {
            search: { value: params.term },
          };

          return query;
        },
        processResults(response) {
          // Transforms the top-level key of the response object from 'items' to 'results'
          return {
            results: response.data,
          };
        },
      },
    });
  });
});

$(document).on("DOMContentLoaded", () => {
  console.log("JSSCROLLPANE");

  const jScrollOptions = {
    autoReinitialise: true,
    autoReinitialiseDelay: 100,
  };
  /* ==========================================================================
	Scroll
	========================================================================== */

  if (!("ontouchstart" in document.documentElement)) {
    document.documentElement.className += " no-touch";

    $(".box-typical-body").jScrollPane(jScrollOptions);
    // $(".side-menu").jScrollPane(jScrollOptions);
    // $('.side-menu-addl').jScrollPane(jScrollOptions);
    $(".scrollable-block").jScrollPane(jScrollOptions);
  }

  /* ==========================================================================
    Header search
    ========================================================================== */

  $(".site-header .site-header-search").each(function() {
    const parent = $(this);
    const overlay = parent.find(".overlay");

    overlay.click(() => {
      parent.removeClass("closed");
    });

    parent.clickoutside(() => {
      if (!parent.hasClass("closed")) {
        parent.addClass("closed");
      }
    });
  });

  /* ==========================================================================
    Header mobile menu
    ========================================================================== */

  // Dropdowns
  $(".site-header-collapsed .dropdown").each(function() {
    const parent = $(this);
    const btn = parent.find(".dropdown-toggle");

    btn.click(() => {
      if (parent.hasClass("mobile-opened")) {
        parent.removeClass("mobile-opened");
      } else {
        parent.addClass("mobile-opened");
      }
    });
  });

  $(".dropdown-more").each(function() {
    const parent = $(this);
    const more = parent.find(".dropdown-more-caption");
    const classOpen = "opened";

    more.click(() => {
      if (parent.hasClass(classOpen)) {
        parent.removeClass(classOpen);
      } else {
        parent.addClass(classOpen);
      }
    });
  });

  // Left mobile menu
  $(".hamburger").click(function() {
    if ($("body").hasClass("menu-left-opened")) {
      $(this).removeClass("is-active");
      $("body").removeClass("menu-left-opened");
      $("html").css("overflow", "auto");
    } else {
      $(this).addClass("is-active");
      $("body").addClass("menu-left-opened");
      $("html").css("overflow", "hidden");
    }
  });

  $(".mobile-menu-left-overlay").click(() => {
    $(".hamburger").removeClass("is-active");
    $("body").removeClass("menu-left-opened");
    $("html").css("overflow", "auto");
  });

  // Right mobile menu
  $(".site-header .burger-right").click(() => {
    if ($("body").hasClass("menu-right-opened")) {
      $("body").removeClass("menu-right-opened");
      $("html").css("overflow", "auto");
    } else {
      $(".hamburger").removeClass("is-active");
      $("body").removeClass("menu-left-opened");
      $("body").addClass("menu-right-opened");
      $("html").css("overflow", "hidden");
    }
  });

  $(".mobile-menu-right-overlay").click(() => {
    $("body").removeClass("menu-right-opened");
    $("html").css("overflow", "auto");
  });

  /* ==========================================================================
    Header help
    ========================================================================== */

  $(".help-dropdown").each(function() {
    const parent = $(this);
    const btn = parent.find(">button");
    const popup = parent.find(".help-dropdown-popup");
    let jscroll;

    btn.click(() => {
      if (parent.hasClass("opened")) {
        parent.removeClass("opened");
        jscroll.destroy();
      } else {
        parent.addClass("opened");

        if (!("ontouchstart" in document.documentElement)) {
          setTimeout(() => {
            jscroll = parent
              .find(".jscroll")
              .jScrollPane(jScrollOptions)
              .data().jsp;
            // jscroll.reinitialise();
          }, 0);
        }
      }
    });

    $("html").click(event => {
      if (
        !$(event.target).closest(".help-dropdown-popup").length &&
        !$(event.target).closest(".help-dropdown>button").length &&
        !$(event.target).is(".help-dropdown-popup") &&
        !$(event.target).is(".help-dropdown>button")
      ) {
        if (parent.hasClass("opened")) {
          parent.removeClass("opened");
          jscroll.destroy();
        }
      }
    });
  });

  /* ==========================================================================
    Side menu list
    ========================================================================== */

  $(".side-menu-list li.with-sub").each(function() {
    const parent = $(this);
    const clickLink = parent.find(">span");
    const subMenu = parent.find(">ul");

    clickLink.click(() => {
      if (parent.hasClass("opened")) {
        parent.removeClass("opened");
        subMenu.slideUp();
        subMenu.find(".opened").removeClass("opened");
      } else {
        if (clickLink.parents(".with-sub").length === 1) {
          $(".side-menu-list .opened")
            .removeClass("opened")
            .find("ul")
            .slideUp();
        }
        parent.addClass("opened");
        subMenu.slideDown();
      }
    });
  });

  /* ==========================================================================
    Dashboard
    ========================================================================== */

  // Collapse box
  $(".box-typical-dashboard").each(function() {
    const parent = $(this);
    const btnCollapse = parent.find(".action-btn-collapse");

    btnCollapse.click(() => {
      if (parent.hasClass("box-typical-collapsed")) {
        parent.removeClass("box-typical-collapsed");
      } else {
        parent.addClass("box-typical-collapsed");
      }
    });
  });

  // Full screen box
  $(".box-typical-dashboard").each(function() {
    const parent = $(this);
    const btnExpand = parent.find(".action-btn-expand");
    const classExpand = "box-typical-full-screen";

    btnExpand.click(() => {
      if (parent.hasClass(classExpand)) {
        parent.removeClass(classExpand);
        $("html").css("overflow", "auto");
      } else {
        parent.addClass(classExpand);
        $("html").css("overflow", "hidden");
      }
    });
  });

  /* ==========================================================================
    Circle progress bar
    ========================================================================== */

  $(".circle-progress-bar").asPieProgress({
    namespace: "asPieProgress",
    speed: 500,
  });

  $(".circle-progress-bar").asPieProgress("start");

  $(".circle-progress-bar-typical").asPieProgress({
    namespace: "asPieProgress",
    speed: 25,
  });

  $(".circle-progress-bar-typical").asPieProgress("start");

  /* ==========================================================================
	Select
	========================================================================== */

  if ($(".bootstrap-select").length) {
    // Bootstrap-select
    $(".bootstrap-select").selectpicker({
      style: "",
      width: "100%",
      size: 8,
    });
  }

  if ($(".select2").length) {
    // Select2
    // $.fn.select2.defaults.set("minimumResultsForSearch", "Infinity");

    $(".select2")
      .not(".manual")
      .select2();

    $(".select2-icon")
      .not(".manual")
      .select2({
        templateSelection: select2Icons,
        templateResult: select2Icons,
      });

    $(".select2-arrow")
      .not(".manual")
      .select2({
        theme: "arrow",
      });

    $(".select2-no-search-arrow").select2({
      minimumResultsForSearch: "Infinity",
      theme: "arrow",
    });

    $(".select2-no-search-default").select2({
      minimumResultsForSearch: "Infinity",
    });

    $(".select2-white")
      .not(".manual")
      .select2({
        theme: "white",
      });

    $(".select2-photo")
      .not(".manual")
      .select2({
        templateSelection: select2Photos,
        templateResult: select2Photos,
      });
  }

  function select2Icons(state) {
    if (!state.id) {
      return state.text;
    }
    const $state = $(
      `<span class="font-icon ${state.element.getAttribute(
        "data-icon"
      )}"></span><span>${state.text}</span>`
    );
    return $state;
  }

  function select2Photos(state) {
    if (!state.id) {
      return state.text;
    }
    const $state = $(
      `<span class="user-item"><img src="${state.element.getAttribute(
        "data-photo"
      )}"/>${state.text}</span>`
    );
    return $state;
  }

  /* ==========================================================================
	Datepicker
	========================================================================== */

  /* ==========================================================================
	Tooltips
	========================================================================== */

  // Tooltip
  $('[data-toggle="tooltip"]').tooltip({
    html: true,
  });

  /* ==========================================================================
	Bar chart
	========================================================================== */

  /* ==========================================================================
	Full height box
	========================================================================== */

  function boxFullHeight() {
    const sectionHeader = $(".section-header");
    let sectionHeaderHeight = 0;

    if (sectionHeader.length) {
      sectionHeaderHeight =
        parseInt(sectionHeader.height()) +
        parseInt(sectionHeader.css("padding-bottom"));
    }

    $(".box-typical-full-height").css(
      "min-height",
      $(window).height() -
        parseInt($(".page-content").css("padding-top")) -
        parseInt($(".page-content").css("padding-bottom")) -
        sectionHeaderHeight -
        parseInt($(".box-typical-full-height").css("margin-bottom")) -
        2
    );
    $(
      ".box-typical-full-height>.tbl, .box-typical-full-height>.box-typical-center"
    ).height(parseInt($(".box-typical-full-height").css("min-height")));
  }

  boxFullHeight();

  $(window).resize(() => {
    boxFullHeight();
  });

  /* ==========================================================================
	Chat
	========================================================================== */

  function chatHeights() {
    $(".chat-dialog-area").height(
      $(window).height() -
        parseInt($(".page-content").css("padding-top")) -
        parseInt($(".page-content").css("padding-bottom")) -
        parseInt($(".chat-container").css("margin-bottom")) -
        2 -
        $(".chat-area-header").outerHeight() -
        $(".chat-area-bottom").outerHeight()
    );
    $(".chat-list-in")
      .height(
        $(window).height() -
          parseInt($(".page-content").css("padding-top")) -
          parseInt($(".page-content").css("padding-bottom")) -
          parseInt($(".chat-container").css("margin-bottom")) -
          2 -
          $(".chat-area-header").outerHeight()
      )
      .css(
        "min-height",
        parseInt($(".chat-dialog-area").css("min-height")) +
          $(".chat-area-bottom").outerHeight()
      );
  }

  chatHeights();

  $(window).resize(() => {
    chatHeights();
  });

  /* ==========================================================================
	Auto size for textarea
	========================================================================== */

  /* ==========================================================================
	Pages center
	========================================================================== */

  function boxWithHeaderFullHeight() {
    /* $('.box-typical-full-height-with-header').each(function(){
			var box = $(this),
				boxHeader = box.find('.box-typical-header'),
				boxBody = box.find('.box-typical-body');

			boxBody.height(
				$(window).height() -
				parseInt($('.page-content').css('padding-top')) -
				parseInt($('.page-content').css('padding-bottom')) -
				parseInt(box.css('margin-bottom')) - 2 -
				boxHeader.outerHeight()
			);
		}); */
  }

  boxWithHeaderFullHeight();

  $(window).resize(() => {
    boxWithHeaderFullHeight();
  });

  /* ==========================================================================
	Gallery
	========================================================================== */

  /* ==========================================================================
	File manager
	========================================================================== */

  function fileManagerHeight() {
    $(".files-manager").each(function() {
      const box = $(this);
      const boxColLeft = box.find(".files-manager-side");
      const boxSubHeader = box.find(".files-manager-header");
      const boxCont = box.find(".files-manager-content-in");
      const boxColRight = box.find(".files-manager-aside");

      const paddings =
        parseInt($(".page-content").css("padding-top")) +
        parseInt($(".page-content").css("padding-bottom")) +
        parseInt(box.css("margin-bottom")) +
        2;

      boxColLeft.height("auto");
      boxCont.height("auto");
      boxColRight.height("auto");

      if (boxColLeft.height() <= $(window).height() - paddings) {
        boxColLeft.height($(window).height() - paddings);
      }

      if (
        boxColRight.height() <=
        $(window).height() - paddings - boxSubHeader.outerHeight()
      ) {
        boxColRight.height(
          $(window).height() - paddings - boxSubHeader.outerHeight()
        );
      }

      boxCont.height(boxColRight.height());
    });
  }

  fileManagerHeight();

  $(window).resize(() => {
    fileManagerHeight();
  });

  /* ==========================================================================
	Mail
	========================================================================== */

  function mailBoxHeight() {
    $(".mail-box").each(function() {
      const box = $(this);
      const boxHeader = box.find(".mail-box-header");
      const boxColLeft = box.find(".mail-box-list");
      const boxSubHeader = box.find(".mail-box-work-area-header");
      const boxColRight = box.find(".mail-box-work-area-cont");

      boxColLeft.height(
        $(window).height() -
          parseInt($(".page-content").css("padding-top")) -
          parseInt($(".page-content").css("padding-bottom")) -
          parseInt(box.css("margin-bottom")) -
          2 -
          boxHeader.outerHeight()
      );

      boxColRight.height(
        $(window).height() -
          parseInt($(".page-content").css("padding-top")) -
          parseInt($(".page-content").css("padding-bottom")) -
          parseInt(box.css("margin-bottom")) -
          2 -
          boxHeader.outerHeight() -
          boxSubHeader.outerHeight()
      );
    });
  }

  mailBoxHeight();

  $(window).resize(() => {
    mailBoxHeight();
  });

  /* ==========================================================================
	Nestable
	========================================================================== */

  $(".dd-handle").hover(
    function() {
      $(this)
        .prev("button")
        .addClass("hover");
      $(this)
        .prev("button")
        .prev("button")
        .addClass("hover");
    },
    function() {
      $(this)
        .prev("button")
        .removeClass("hover");
      $(this)
        .prev("button")
        .prev("button")
        .removeClass("hover");
    }
  );

  /* ==========================================================================
	Addl side menu
	========================================================================== */

  setTimeout(() => {
    if (!("ontouchstart" in document.documentElement)) {
      $(".side-menu-addl").jScrollPane(jScrollOptions);
    }
  }, 1000);

  /* ==========================================================================
	Widget chart combo
	========================================================================== */

  /* ==========================================================================
	Header notifications
	========================================================================== */

  // Tabs hack
  $('.dropdown-menu-messages a[data-toggle="tab"]').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $(this).tab("show");

    // Scroll
    if (!("ontouchstart" in document.documentElement)) {
      jspMessNotif = $(".dropdown-notification.messages .tab-pane.active")
        .jScrollPane(jScrollOptions)
        .data().jsp;
    }
  });

  // Scroll
  let jspMessNotif;
  let jspNotif;

  $(".dropdown-notification.messages").on("show.bs.dropdown", () => {
    if (!("ontouchstart" in document.documentElement)) {
      jspMessNotif = $(".dropdown-notification.messages .tab-pane.active")
        .jScrollPane(jScrollOptions)
        .data().jsp;
    }
  });

  $(".dropdown-notification.messages").on("hide.bs.dropdown", () => {
    if (!("ontouchstart" in document.documentElement)) {
      jspMessNotif.destroy();
    }
  });

  $(".dropdown-notification.notif").on("show.bs.dropdown", () => {
    if (!("ontouchstart" in document.documentElement)) {
      jspNotif = $(".dropdown-notification.notif .dropdown-menu-notif-list")
        .jScrollPane(jScrollOptions)
        .data().jsp;
    }
  });

  $(".dropdown-notification.notif").on("hide.bs.dropdown", () => {
    if (!("ontouchstart" in document.documentElement)) {
      jspNotif.destroy();
    }
  });

  /* ==========================================================================
	Steps progress
	========================================================================== */

  function stepsProgresMarkup() {
    $(".steps-icon-progress").each(function() {
      const parent = $(this);
      const cont = parent.find("ul");
      let padding = 0;
      const padLeft =
        (parent.find("li:first-child").width() -
          parent.find("li:first-child .caption").width()) /
        2;
      const padRight =
        (parent.find("li:last-child").width() -
          parent.find("li:last-child .caption").width()) /
        2;

      padding = padLeft;

      if (padLeft > padRight) padding = padRight;

      cont.css({
        marginLeft: -padding,
        marginRight: -padding,
      });
    });
  }

  stepsProgresMarkup();

  $(window).resize(() => {
    stepsProgresMarkup();
  });

  /* ========================================================================== */

  $(".control-panel-toggle").on("click", function() {
    const self = $(this);

    if (self.hasClass("open")) {
      self.removeClass("open");
      $(".control-panel").removeClass("open");
    } else {
      self.addClass("open");
      $(".control-panel").addClass("open");
    }
  });

  $(".control-item-header .icon-toggle, .control-item-header .text").on(
    "click",
    function() {
      const content = $(this)
        .closest("li")
        .find(".control-item-content");

      if (content.hasClass("open")) {
        content.removeClass("open");
      } else {
        $(".control-item-content.open").removeClass("open");
        content.addClass("open");
      }
    }
  );

  $.browser = {};
  $.browser.chrome = /chrome/.test(navigator.userAgent.toLowerCase());
  $.browser.msie = /msie/.test(navigator.userAgent.toLowerCase());
  $.browser.mozilla = /firefox/.test(navigator.userAgent.toLowerCase());

  if ($.browser.chrome) {
    $("body").addClass("chrome-browser");
  } else if ($.browser.msie) {
    $("body").addClass("msie-browser");
  } else if ($.browser.mozilla) {
    $("body").addClass("mozilla-browser");
  }
});
